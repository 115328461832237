<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <dx-data-grid
        ref="refEmployeeDetailGrid"
        :data-source="employeeInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="사번"
        :focused-row-key.sync="focusedRowKey"
        @editor-preparing="onEditorPreparing"
        @row-updated="onRowUpdated"
        @row-updating="onUpdating"
        @row-inserted="onRowInserted"
        @row-inserting="onRowInserting"
        @row-removed="onRowRemoved"
        @row-removing="onRowRemoving"
      >
        <DxColumnFixing :enabled="true" />
        <DxEditing
          :allow-deleting="true"
          :allow-updating="true"
          :allow-adding="true"
          :use-icons="true"
          mode="row"
        />
        <DxColumn
          :allow-editing="isEditing"
          caption="ID"
          data-field="사번"
          :min-width="100"
        >
          <dx-required-rule message="항목을 입력하세요" />
        </DxColumn>
        <DxColumn
          :allow-editing="true"
          caption="성명"
          data-field="성명"
          :min-width="100"
        >
          <dx-required-rule message="항목을 입력하세요" />
        </DxColumn>
        <DxColumn
          :allow-editing="true"
          caption="암호"
          data-field="암호"
          :min-width="100"
        >
          <DxStringLengthRule
            :max="16"
            :min="6"
            message="비밀번호는 6-16자리 입니다."
          />
          <dx-required-rule message="항목을 입력하세요" />
        </DxColumn>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxEditing, DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing } from 'devextreme-vue/data-grid'
import {
  DxRequiredRule,
  DxStringLengthRule
} from 'devextreme-vue/validator'
// import AppLib from '../../share/AppLib'
// import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxColumnFixing,
    DxEditing,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxRequiredRule,
    DxStringLengthRule
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      employeeInfoList: null,
      focusedRowKey: null,
      isEditing: true,
      gridHeight: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.refreshEmployee()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 90)
  },
  updated () {
  },
  methods: {
    onEditorPreparing (e) {
      /*
      if (e.dataField === '사번' && e.row !== undefined) {
        if (e.row.isNewRow === true) {
          this.isEditing = true
        } else {
          this.isEditing = false
        }
      }
      */
    },
    onRowUpdated (row) {
      console.log(row.data)
      this.isLoading = true
      this.$_sp.runUpdateSqlProc('직원정보', ['사번'], null, [row.data])
        .then((data) => {
          this.isLoading = false
        })
    },
    onUpdating (row) {
      if (row.newData.사번 !== undefined) {
        this.$snotify.info('ID는 변경할 수 없습니다.')
        row.cancel = true
      }
    },
    onRowInserted (row) {
      console.log(row.data)
      this.isLoading = true
      this.$_sp.runInsertSqlProc('직원정보', null, [row.data])
        .then((data) => {
          this.isLoading = false
        })
    },
    onRowInserting (row) {
      if (this.$_.findIndex(this.employeeInfoList, { 사번: row.data.사번 }) >= 0) {
        this.$snotify.info('사용중인 ID입니다.')
        row.cancel = true
      }
    },
    onRowRemoved (row) {
      this.isLoading = true
      this.$_sp.runDeleteSqlProc('직원정보', [{ 사번: row.data.사번 }])
        .then((data) => {
          this.isLoading = false
        })
    },
    onRowRemoving (row) {

    },
    refreshEmployee () {
      this.isLoading = true
      this.$_sp.runNoEncodeFindSqlProc('직원정보', null)
        .then((data) => {
          this.isLoading = false
          this.employeeInfoList = this.$_sp.MakeModel(data)
          console.log(this.employeeInfoList)
        })
    }
  }
}
</script>

<style lang="scss">

</style>
